import { Brain, Focus, Zap, Lightbulb, ArrowLeft } from 'lucide-react';
import { Link } from 'react-router-dom';

export default function FocusStack() {
  return (
    <div className="min-h-screen bg-soft-mint py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="mb-8">
          <Link to="/" className="inline-flex items-center text-deep-teal hover:text-royal-purple transition-colors">
            <ArrowLeft className="w-5 h-5 mr-2" />
            Back to Home
          </Link>
        </div>

        <div className="bg-white rounded-2xl shadow-xl overflow-hidden">
          <div className="relative h-64">
            <img 
              src="https://images.unsplash.com/photo-1499951360447-b19be8fe80f5?auto=format&fit=crop&q=80&w=2000"
              alt="Focus and productivity"
              className="w-full h-full object-cover"
            />
            <div className="absolute inset-0 bg-gradient-to-r from-deep-teal/80 to-royal-purple/80 flex items-center justify-center">
              <h1 className="text-4xl font-bold text-white text-center px-4">Focus Enhancement Stack</h1>
            </div>
          </div>

          <div className="p-8">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-12">
              <div>
                <h2 className="text-2xl font-bold text-navy mb-4 flex items-center">
                  <Brain className="w-6 h-6 mr-2 text-deep-teal" />
                  Core Benefits
                </h2>
                <ul className="space-y-3 text-gray-600">
                  <li className="flex items-start">
                    <Focus className="w-5 h-5 mr-2 text-deep-teal flex-shrink-0 mt-1" />
                    Enhanced mental clarity and concentration
                  </li>
                  <li className="flex items-start">
                    <Zap className="w-5 h-5 mr-2 text-deep-teal flex-shrink-0 mt-1" />
                    Improved cognitive processing speed
                  </li>
                  <li className="flex items-start">
                    <Lightbulb className="w-5 h-5 mr-2 text-deep-teal flex-shrink-0 mt-1" />
                    Better information retention and recall
                  </li>
                </ul>
              </div>

              <div className="bg-soft-mint rounded-lg p-6">
                <h2 className="text-2xl font-bold text-navy mb-4">Key Ingredients</h2>
                <div className="space-y-4">
                  <div>
                    <h3 className="font-semibold text-deep-teal">Lion's Mane Mushroom (500mg)</h3>
                    <p className="text-gray-600">Natural nootropic that supports nerve growth factor (NGF) production, enhancing cognitive function and neural health.</p>
                  </div>
                  <div>
                    <h3 className="font-semibold text-deep-teal">Alpha GPC (300mg)</h3>
                    <p className="text-gray-600">High-quality choline source that supports acetylcholine production, crucial for memory and learning.</p>
                  </div>
                  <div>
                    <h3 className="font-semibold text-deep-teal">N-Acetyl L-Tyrosine (350mg)</h3>
                    <p className="text-gray-600">Supports dopamine production, helping maintain focus during stressful or demanding tasks.</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-gradient-to-r from-deep-teal/10 to-royal-purple/10 rounded-lg p-6">
              <h2 className="text-2xl font-bold text-navy mb-4">Optimal Usage</h2>
              <div className="prose max-w-none text-gray-600">
                <p className="mb-4">
                  The Focus Enhancement Stack is designed to be taken in the morning or early afternoon to support peak cognitive performance throughout the day. For best results:
                </p>
                <ul className="list-disc pl-5 space-y-2">
                  <li>Take with breakfast or lunch for optimal absorption</li>
                  <li>Stay hydrated throughout the day</li>
                  <li>Cycle 5 days on, 2 days off to maintain effectiveness</li>
                  <li>Allow 2-3 weeks for full benefits to develop</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}