import { useState } from 'react';
import { quizQuestions } from '../data/quiz';
import { FormData } from '../types';
import { Send, ArrowLeft, AlertTriangle, Mail } from 'lucide-react';
import { analyzeAnswers, determineResults } from '../utils/quizLogic';

function Quiz() {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answers, setAnswers] = useState<{ [key: number]: number | number[] }>({});
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const [showEmailForm, setShowEmailForm] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [formData, setFormData] = useState<FormData>({
    email: '',
    name: '',
    answers: {}
  });

  const handleSingleAnswer = (optionIndex: number) => {
    const newAnswers = { ...answers, [currentQuestion]: optionIndex };
    setAnswers(newAnswers);
    if (currentQuestion < quizQuestions.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
    } else {
      setShowDisclaimer(true);
    }
  };

  const handleMultipleAnswer = (optionIndex: number) => {
    const currentAnswers = Array.isArray(answers[currentQuestion]) 
      ? answers[currentQuestion] as number[]
      : [];
    
    const newAnswers = { ...answers };
    
    if (currentAnswers.includes(optionIndex)) {
      newAnswers[currentQuestion] = currentAnswers.filter(a => a !== optionIndex);
    } else if (currentAnswers.length < 3) {
      newAnswers[currentQuestion] = [...currentAnswers, optionIndex];
    }
    
    setAnswers(newAnswers);
  };

  const handleNext = () => {
    if (currentQuestion < quizQuestions.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
    } else {
      setShowDisclaimer(true);
    }
  };

  const handleBack = () => {
    if (currentQuestion > 0) {
      setCurrentQuestion(currentQuestion - 1);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError(null);

    try {
      // Analyze answers and get results
      const analysis = analyzeAnswers(answers);
      const personalizedResults = determineResults(analysis);

      // Log the data being sent for debugging
      console.log('Submitting quiz data:', {
        email: formData.email,
        name: formData.name,
        results: personalizedResults
      });

      const response = await fetch('/.netlify/functions/submit-quiz', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: formData.email,
          name: formData.name,
          results: personalizedResults
        }),
      });

      const data = await response.json();
      console.log('Server response:', data); // Log server response

      if (!response.ok) {
        throw new Error(data?.message || 'Failed to send results');
      }

      // Store results in localStorage
      localStorage.setItem('quizResults', JSON.stringify({
        name: formData.name,
        email: formData.email,
        results: personalizedResults,
        timestamp: new Date().toISOString()
      }));

      alert('Thank you! Your personalized recommendations have been sent to your email.');
    } catch (error) {
      console.error('Error submitting quiz:', error);
      setError(error instanceof Error ? error.message : 'Failed to send results. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  if (showEmailForm) {
    return (
      <div className="min-h-screen bg-gradient-to-b from-white to-soft-mint/30 py-12">
        <div className="max-w-xl mx-auto">
          <div className="bg-white/80 backdrop-blur-sm rounded-2xl shadow-lg p-8">
            <div className="flex items-center justify-center mb-8 text-deep-teal">
              <Mail className="w-12 h-12" />
            </div>
            <h2 className="text-2xl font-bold mb-2 text-navy text-center">Get Your Personalized Blend</h2>
            <p className="text-gray-600 mb-8 text-center">
              We'll craft your unique supplement recommendations and send them straight to your inbox.
            </p>

            <form onSubmit={handleSubmit} className="space-y-6">
              {error && (
                <div className="p-4 bg-red-50 border border-red-200 rounded-lg text-red-600">
                  {error}
                </div>
              )}
              <div>
                <label className="block text-sm font-medium text-navy mb-1">Name</label>
                <input
                  type="text"
                  required
                  className="w-full px-4 py-2 rounded-lg border border-gray-200 focus:border-deep-teal focus:ring-2 focus:ring-deep-teal/20 transition-colors"
                  value={formData.name}
                  onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-navy mb-1">Email</label>
                <input
                  type="email"
                  required
                  className="w-full px-4 py-2 rounded-lg border border-gray-200 focus:border-deep-teal focus:ring-2 focus:ring-deep-teal/20 transition-colors"
                  value={formData.email}
                  onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                />
              </div>

              <div className="bg-soft-mint/50 p-4 rounded-lg">
                <p className="text-sm text-gray-600">
                  By submitting, you agree to receive personalized recommendations and wellness insights. You can unsubscribe anytime.
                </p>
              </div>

              <div className="flex justify-between gap-4">
                <button
                  type="button"
                  onClick={() => setShowEmailForm(false)}
                  className="flex-1 px-4 py-2 border-2 border-deep-teal text-deep-teal rounded-lg hover:bg-deep-teal hover:text-white transition-colors"
                >
                  Back
                </button>
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="flex-1 flex items-center justify-center px-4 py-2 bg-deep-teal text-white rounded-lg hover:bg-royal-purple transition-colors disabled:opacity-50"
                >
                  {isSubmitting ? (
                    <span className="flex items-center">
                      <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      Creating Your Blend...
                    </span>
                  ) : (
                    <>
                      <Send className="w-5 h-5 mr-2" />
                      Get Your Blend
                    </>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }

  if (showDisclaimer) {
    return (
      <div className="min-h-screen bg-gradient-to-b from-white to-soft-mint/30 py-12">
        <div className="max-w-xl mx-auto">
          <div className="bg-white/80 backdrop-blur-sm rounded-2xl shadow-lg p-8">
            <div className="flex items-center justify-center mb-8 text-deep-teal">
              <AlertTriangle className="w-12 h-12" />
            </div>
            <h2 className="text-2xl font-bold mb-4 text-navy text-center">Before We Create Your Blend</h2>
            <div className="space-y-4 text-gray-600">
              <p className="text-center mb-6">
                Our recommendations are for informational purposes and should be considered as general wellness suggestions.
              </p>
              <div className="space-y-4">
                <div className="p-4 bg-soft-mint/30 rounded-lg">
                  <h3 className="font-medium text-navy mb-2">Important Notes:</h3>
                  <ul className="list-disc pl-5 space-y-2">
                    <li>Consult healthcare professionals before starting supplements</li>
                    <li>Individual results may vary</li>
                    <li>Not a substitute for medical advice</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="flex justify-between gap-4 mt-8">
              <button
                onClick={() => setCurrentQuestion(quizQuestions.length - 1)}
                className="flex-1 px-4 py-2 border-2 border-deep-teal text-deep-teal rounded-lg hover:bg-deep-teal hover:text-white transition-colors"
              >
                Back
              </button>
              <button
                onClick={() => {
                  setShowDisclaimer(false);
                  setShowEmailForm(true);
                }}
                className="flex-1 px-4 py-2 bg-deep-teal text-white rounded-lg hover:bg-royal-purple transition-colors"
              >
                I Understand
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const currentQ = quizQuestions[currentQuestion];
  const isMultipleType = currentQ.type === 'multiple';
  const currentAnswers = Array.isArray(answers[currentQuestion]) 
    ? answers[currentQuestion] as number[]
    : [];

  return (
    <div className="min-h-screen bg-gradient-to-b from-white to-soft-mint/30 py-12">
      <div className="max-w-xl mx-auto">
        <div className="bg-white/80 backdrop-blur-sm rounded-2xl shadow-lg p-8">
          <div className="mb-8">
            <div className="w-full bg-gray-100 rounded-full h-1 mb-6">
              <div
                className="bg-gradient-to-r from-deep-teal to-royal-purple h-1 rounded-full transition-all duration-300"
                style={{ width: `${((currentQuestion + 1) / quizQuestions.length) * 100}%` }}
              ></div>
            </div>
          </div>

          {currentQuestion > 0 && (
            <button
              onClick={handleBack}
              className="mb-6 text-deep-teal hover:text-royal-purple transition-colors flex items-center"
            >
              <ArrowLeft className="w-4 h-4 mr-1" />
              Previous
            </button>
          )}

          <h3 className="text-xl font-medium mb-6 text-navy">{currentQ.question}</h3>
          
          <div className="space-y-3">
            {currentQ.options.map((option, index) => {
              const isSelected = isMultipleType 
                ? currentAnswers.includes(index)
                : answers[currentQuestion] === index;
              
              return (
                <button
                  key={index}
                  onClick={() => isMultipleType ? handleMultipleAnswer(index) : handleSingleAnswer(index)}
                  className={`group w-full text-left px-6 py-4 rounded-xl border-2 transition-all duration-200
                    ${isSelected 
                      ? 'border-deep-teal bg-deep-teal/5 text-deep-teal' 
                      : 'border-gray-100 hover:border-deep-teal/30 hover:bg-deep-teal/5'
                    }`}
                  disabled={isMultipleType && currentAnswers.length >= 3 && !isSelected}
                >
                  <div className="flex items-center">
                    <div className={`w-4 h-4 rounded-full border-2 mr-3 transition-colors
                      ${isSelected 
                        ? 'border-deep-teal bg-deep-teal' 
                        : 'border-gray-300 group-hover:border-deep-teal/50'
                      }`}
                    />
                    {option}
                  </div>
                </button>
              );
            })}
            
            {isMultipleType && (
              <div className="flex justify-between items-center mt-6">
                <span className="text-sm text-gray-500">
                  Select up to 3 options
                </span>
                <button
                  onClick={handleNext}
                  className="px-6 py-2 bg-deep-teal text-white rounded-lg hover:bg-royal-purple transition-colors disabled:opacity-50 disabled:hover:bg-deep-teal"
                  disabled={currentAnswers.length === 0}
                >
                  Continue
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Quiz;