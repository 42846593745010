import { Battery, Zap, Flame, Sparkles, ArrowLeft } from 'lucide-react';
import { Link } from 'react-router-dom';

export default function EnergyStack() {
  return (
    <div className="min-h-screen bg-soft-mint py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="mb-8">
          <Link to="/" className="inline-flex items-center text-deep-teal hover:text-royal-purple transition-colors">
            <ArrowLeft className="w-5 h-5 mr-2" />
            Back to Home
          </Link>
        </div>

        <div className="bg-white rounded-2xl shadow-xl overflow-hidden">
          <div className="relative h-64">
            <img 
              src="https://images.unsplash.com/photo-1600618528240-fb9fc964b853?auto=format&fit=crop&q=80&w=2000"
              alt="Energy and vitality"
              className="w-full h-full object-cover"
            />
            <div className="absolute inset-0 bg-gradient-to-r from-deep-teal/80 to-royal-purple/80 flex items-center justify-center">
              <h1 className="text-4xl font-bold text-white text-center px-4">Energy & Vitality Stack</h1>
            </div>
          </div>

          <div className="p-8">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-12">
              <div>
                <h2 className="text-2xl font-bold text-navy mb-4 flex items-center">
                  <Battery className="w-6 h-6 mr-2 text-deep-teal" />
                  Core Benefits
                </h2>
                <ul className="space-y-3 text-gray-600">
                  <li className="flex items-start">
                    <Zap className="w-5 h-5 mr-2 text-deep-teal flex-shrink-0 mt-1" />
                    Sustained natural energy throughout the day
                  </li>
                  <li className="flex items-start">
                    <Flame className="w-5 h-5 mr-2 text-deep-teal flex-shrink-0 mt-1" />
                    Enhanced metabolic function and vitality
                  </li>
                  <li className="flex items-start">
                    <Sparkles className="w-5 h-5 mr-2 text-deep-teal flex-shrink-0 mt-1" />
                    Improved cellular energy production
                  </li>
                </ul>
              </div>

              <div className="bg-soft-mint rounded-lg p-6">
                <h2 className="text-2xl font-bold text-navy mb-4">Key Ingredients</h2>
                <div className="space-y-4">
                  <div>
                    <h3 className="font-semibold text-deep-teal">Cordyceps Mushroom (500mg)</h3>
                    <p className="text-gray-600">Natural energy enhancer that improves oxygen utilization and ATP production.</p>
                  </div>
                  <div>
                    <h3 className="font-semibold text-deep-teal">PQQ (20mg)</h3>
                    <p className="text-gray-600">Supports mitochondrial function and energy production at the cellular level.</p>
                  </div>
                  <div>
                    <h3 className="font-semibold text-deep-teal">CoQ10 (100mg)</h3>
                    <p className="text-gray-600">Essential for cellular energy production and cardiovascular health.</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-gradient-to-r from-deep-teal/10 to-royal-purple/10 rounded-lg p-6">
              <h2 className="text-2xl font-bold text-navy mb-4">Optimal Usage</h2>
              <div className="prose max-w-none text-gray-600">
                <p className="mb-4">
                  The Energy & Vitality Stack is designed to provide sustainable energy without crashes or jitters. For best results:
                </p>
                <ul className="list-disc pl-5 space-y-2">
                  <li>Take with breakfast to establish stable energy levels</li>
                  <li>Stay hydrated throughout the day</li>
                  <li>Can be combined with light exercise for enhanced effects</li>
                  <li>Effects typically noticeable within 2-3 hours</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}