import { QuizQuestion, QuizResult } from '../types';

export const quizQuestions: QuizQuestion[] = [
  {
    id: 1,
    question: "Age",
    type: "single",
    options: ["18-25", "26-35", "36-45", "46-55", "56+"]
  },
  {
    id: 2,
    question: "Gender",
    type: "single",
    options: ["Male", "Female", "Prefer not to say"]
  },
  {
    id: 3,
    question: "What are your personal goals? (Select up to 3)",
    type: "multiple",
    options: [
      "Improve focus",
      "Enhance memory",
      "Reduce stress",
      "Increase mental energy",
      "Boost creativity",
      "Improve sleep quality",
      "Enhance overall cognitive performance"
    ]
  },
  {
    id: 4,
    question: "Cognitive Challenges (Select up to 3)",
    type: "multiple",
    options: [
      "Brain fog",
      "Difficulty concentrating",
      "Memory issues",
      "Lack of mental clarity",
      "Low motivation",
      "Mental fatigue",
      "Anxiety",
      "Mood swings"
    ]
  },
  {
    id: 5,
    question: "How long does it take to fall asleep?",
    type: "single",
    options: [
      "Less than 15 minutes",
      "15-30 minutes",
      "30-45 minutes",
      "More than 45 minutes"
    ]
  },
  {
    id: 6,
    question: "Do you have issues staying asleep?",
    type: "single",
    options: [
      "Never",
      "Rarely",
      "Occasionally",
      "Frequently"
    ]
  },
  {
    id: 7,
    question: "Temperature Regulation During Sleep",
    type: "single",
    options: [
      "Never",
      "Rarely",
      "Occasionally",
      "Frequently"
    ]
  },
  {
    id: 8,
    question: "Context of Mental Performance",
    type: "single",
    options: [
      "Student",
      "Professional/Office Worker",
      "Creative Professional",
      "Entrepreneur",
      "Shift Worker",
      "Athlete/Physical Performer",
      "Other"
    ]
  }
];

export const quizResults: QuizResult[] = [
  {
    category: "Focus & Performance Stack",
    description: "Based on your profile, we've identified a need for enhanced focus and mental performance. This customized stack is designed to support your cognitive function while addressing your specific challenges.",
    recommendations: [
      "Lion's Mane Mushroom - Supports neural growth and focus",
      "Alpha GPC - Enhances memory and learning capacity",
      "L-Theanine - Promotes calm alertness",
      "Rhodiola Rosea - Improves mental stamina"
    ]
  },
  {
    category: "Sleep & Recovery Stack",
    description: "Your responses indicate that optimizing sleep and recovery should be a priority. This stack is formulated to help regulate your sleep cycle and improve sleep quality.",
    recommendations: [
      "Magnesium L-Threonate - Supports sleep quality and cognitive function",
      "L-Tryptophan - Helps with sleep onset",
      "Ashwagandha - Reduces stress and promotes relaxation",
      "Glycine - Improves sleep quality and daytime alertness"
    ]
  },
  {
    category: "Energy & Clarity Stack",
    description: "Your profile suggests a need for sustained energy and mental clarity throughout the day. This stack is designed to combat fatigue while maintaining focus.",
    recommendations: [
      "B-Complex Vitamins - Support energy metabolism",
      "Panax Ginseng - Enhances mental energy",
      "CDP-Choline - Improves focus and mental clarity",
      "CoQ10 - Supports cellular energy production"
    ]
  },
  {
    category: "Mood & Balance Stack",
    description: "Based on your responses, we recommend focusing on mood regulation and stress management. This stack is formulated to support emotional balance and cognitive performance.",
    recommendations: [
      "5-HTP - Supports mood regulation",
      "L-Theanine - Promotes relaxation without drowsiness",
      "Bacopa Monnieri - Reduces anxiety while supporting cognition",
      "Omega-3 DHA/EPA - Essential for brain health and mood"
    ]
  }
];