import { Brain, Book, Zap, Lightbulb, ArrowLeft } from 'lucide-react';
import { Link } from 'react-router-dom';

export default function MemoryStack() {
  return (
    <div className="min-h-screen bg-soft-mint py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="mb-8">
          <Link to="/" className="inline-flex items-center text-deep-teal hover:text-royal-purple transition-colors">
            <ArrowLeft className="w-5 h-5 mr-2" />
            Back to Home
          </Link>
        </div>

        <div className="bg-white rounded-2xl shadow-xl overflow-hidden">
          <div className="relative h-64">
            <img 
              src="https://images.unsplash.com/photo-1617791160505-6f00504e3519?auto=format&fit=crop&q=80&w=2000"
              alt="Memory and learning"
              className="w-full h-full object-cover"
            />
            <div className="absolute inset-0 bg-gradient-to-r from-deep-teal/80 to-royal-purple/80 flex items-center justify-center">
              <h1 className="text-4xl font-bold text-white text-center px-4">Memory Optimization Stack</h1>
            </div>
          </div>

          <div className="p-8">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-12">
              <div>
                <h2 className="text-2xl font-bold text-navy mb-4 flex items-center">
                  <Brain className="w-6 h-6 mr-2 text-deep-teal" />
                  Core Benefits
                </h2>
                <ul className="space-y-3 text-gray-600">
                  <li className="flex items-start">
                    <Book className="w-5 h-5 mr-2 text-deep-teal flex-shrink-0 mt-1" />
                    Enhanced learning and memory formation
                  </li>
                  <li className="flex items-start">
                    <Zap className="w-5 h-5 mr-2 text-deep-teal flex-shrink-0 mt-1" />
                    Improved information recall speed
                  </li>
                  <li className="flex items-start">
                    <Lightbulb className="w-5 h-5 mr-2 text-deep-teal flex-shrink-0 mt-1" />
                    Better long-term memory consolidation
                  </li>
                </ul>
              </div>

              <div className="bg-soft-mint rounded-lg p-6">
                <h2 className="text-2xl font-bold text-navy mb-4">Key Ingredients</h2>
                <div className="space-y-4">
                  <div>
                    <h3 className="font-semibold text-deep-teal">Bacopa Monnieri (300mg)</h3>
                    <p className="text-gray-600">Traditional herb that enhances memory formation and retention while reducing anxiety.</p>
                  </div>
                  <div>
                    <h3 className="font-semibold text-deep-teal">Citicoline (250mg)</h3>
                    <p className="text-gray-600">Advanced form of choline that supports brain energy and neurotransmitter production.</p>
                  </div>
                  <div>
                    <h3 className="font-semibold text-deep-teal">Ginkgo Biloba (120mg)</h3>
                    <p className="text-gray-600">Improves blood flow to the brain and enhances cognitive function and memory.</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-gradient-to-r from-deep-teal/10 to-royal-purple/10 rounded-lg p-6">
              <h2 className="text-2xl font-bold text-navy mb-4">Optimal Usage</h2>
              <div className="prose max-w-none text-gray-600">
                <p className="mb-4">
                  The Memory Optimization Stack is designed to support both short-term and long-term memory enhancement. For optimal results:
                </p>
                <ul className="list-disc pl-5 space-y-2">
                  <li>Take consistently each morning with food</li>
                  <li>Combine with regular mental exercises</li>
                  <li>Maintain a consistent sleep schedule</li>
                  <li>Allow 4-6 weeks for maximum benefits</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}