import { Brain } from 'lucide-react';
import { BrowserRouter as Router, Routes, Route, Link, useLocation } from 'react-router-dom';
import Quiz from './components/Quiz';
import FocusStack from './components/FocusStack';
import MemoryStack from './components/MemoryStack';
import EnergyStack from './components/EnergyStack';

const scrollToSection = (sectionId: string) => {
  const element = document.getElementById(sectionId);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
  }
};

const useNavigationHandler = () => {
  const location = useLocation();
  
  return (sectionId: string) => (e: React.MouseEvent) => {
    e.preventDefault();
    if (location.pathname === '/') {
      scrollToSection(sectionId);
    } else {
      window.location.href = `/#${sectionId}`;
    }
  };
};

function HomePage() {
  return (
    <>
      <div id="hero" className="relative min-h-screen bg-gradient-to-r from-deep-teal to-royal-purple flex items-center">
        <div className="max-w-7xl mx-auto px-4 py-24 sm:px-6 lg:px-8 w-full">
          <div className="text-center">
            <h1 className="text-4xl font-extrabold tracking-tight text-white sm:text-5xl md:text-6xl">
              Optimize Your Mental Performance
            </h1>
            <p className="mt-3 max-w-md mx-auto text-base text-light-lavender sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
              Discover personalized nootropic recommendations based on your unique needs and goals.
            </p>
            <div className="mt-8">
              <Link
                to="/quiz"
                className="inline-flex items-center px-8 py-4 text-lg font-bold text-white bg-transparent border-2 border-white rounded-full hover:bg-white hover:text-deep-teal transition-all duration-300"
              >
                Discover Your Blend
                <svg
                  className="ml-2 w-5 h-5"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </Link>
            </div>
          </div>
        </div>
        <div className="absolute bottom-8 left-1/2 transform -translate-x-1/2 text-white/80">
          <div className="animate-bounce">
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 14l-7 7m0 0l-7-7m7 7V3" />
            </svg>
          </div>
        </div>
      </div>

      <div id="products" className="relative bg-gradient-to-b from-soft-mint via-white to-light-lavender">
        <div className="max-w-7xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-extrabold text-navy mb-8">Alchemy Explained</h2>
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
            {[
              {
                name: "Focus Enhancement Stack",
                image: "https://images.unsplash.com/photo-1633613286991-611fe299c4be?auto=format&fit=crop&q=80&w=500",
                description: "Boost your concentration and mental clarity",
                link: "/focus-stack"
              },
              {
                name: "Memory Optimization",
                image: "https://images.unsplash.com/photo-1647427060118-4911c9821b82?auto=format&fit=crop&q=80&w=500",
                description: "Enhance your cognitive performance",
                link: "/memory-stack"
              },
              {
                name: "Energy & Vitality",
                image: "https://images.unsplash.com/photo-1600618528240-fb9fc964b853?auto=format&fit=crop&q=80&w=500",
                description: "Natural energy enhancement supplements",
                link: "/energy-stack"
              }
            ].map((product, index) => (
              <div key={index} className="bg-white/80 backdrop-blur-sm rounded-lg shadow-md overflow-hidden transform transition-transform hover:scale-105">
                <div className="relative">
                  <img src={product.image} alt={product.name} className="w-full h-48 object-cover" />
                  <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent"></div>
                </div>
                <div className="p-6">
                  <h3 className="text-lg font-semibold text-navy">{product.name}</h3>
                  <p className="mt-2 text-gray-600">{product.description}</p>
                  {product.link ? (
                    <Link to={product.link} className="mt-4 block w-full bg-deep-teal text-white px-4 py-2 rounded-md hover:bg-royal-purple transition-colors text-center">
                      Learn More
                    </Link>
                  ) : (
                    <button className="mt-4 w-full bg-deep-teal text-white px-4 py-2 rounded-md hover:bg-royal-purple transition-colors">
                      Coming Soon
                    </button>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div id="about" className="bg-gradient-to-b from-light-lavender via-light-lavender to-navy/10">
        <div className="max-w-7xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
            <div>
              <h2 className="text-3xl font-extrabold text-navy mb-6">About Cognitive Alchemy</h2>
              <p className="text-lg text-gray-600 mb-6">
                We're dedicated to helping you achieve optimal cognitive performance through carefully selected, science-backed supplements. Our expert team researches and curates the best nootropics to support your mental clarity, focus, and overall brain health.
              </p>
              <p className="text-lg text-gray-600">
                All our products are third-party tested for purity and potency, ensuring you receive the highest quality supplements for your cognitive enhancement journey.
              </p>
            </div>
            <div className="relative h-96">
              <img
                src="https://images.unsplash.com/photo-1616198814651-e71f960c3180?auto=format&fit=crop&q=80&w=500"
                alt="Natural supplements"
                className="rounded-lg shadow-xl object-cover w-full h-full"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function Navigation() {
  const handleClick = useNavigationHandler();
  
  return (
    <nav className="hidden md:flex space-x-8">
      <a href="#products" onClick={handleClick('products')} className="text-navy hover:text-deep-teal">Learn</a>
      <Link to="/quiz" className="text-navy hover:text-deep-teal">Discover Your Blend</Link>
      <a href="#about" onClick={handleClick('about')} className="text-navy hover:text-deep-teal">About</a>
    </nav>
  );
}

function Footer() {
  const handleClick = useNavigationHandler();
  
  return (
    <footer className="bg-navy text-white">
      <div className="max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div>
            <div className="flex items-center">
              <Brain className="h-8 w-8 text-deep-teal" />
              <span className="ml-2 text-2xl font-bold">Cognitive Alchemy</span>
            </div>
            <p className="mt-4 text-light-lavender">
              Enhancing human potential through advanced supplementation.
            </p>
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-4">Quick Links</h3>
            <ul className="space-y-2">
              <li><a href="#products" onClick={handleClick('products')} className="text-light-lavender hover:text-white">Learn</a></li>
              <li><Link to="/quiz" className="text-light-lavender hover:text-white">Discover Your Blend</Link></li>
              <li><a href="#about" onClick={handleClick('about')} className="text-light-lavender hover:text-white">About</a></li>
            </ul>
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-4">Contact</h3>
            <p className="text-light-lavender">Email: info@cognitivealchemy.com</p>
            <p className="text-light-lavender">Phone: (555) 123-4567</p>
          </div>
        </div>
        <div className="mt-8 pt-8 border-t border-light-lavender/20 text-center text-light-lavender">
          <p>&copy; 2025 Cognitive Alchemy. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
}

function App() {
  return (
    <Router>
      <div className="min-h-screen bg-soft-mint">
        <header className="bg-gradient-to-r from-deep-teal/5 to-royal-purple/5">
          <div className="max-w-7xl mx-auto px-4 py-6 sm:px-6 lg:px-8">
            <div className="flex items-center justify-between">
              <Link to="/" className="flex items-center">
                <Brain className="h-8 w-8 text-deep-teal" />
                <span className="ml-2 text-2xl font-bold text-navy">Cognitive Alchemy</span>
              </Link>
              <Navigation />
            </div>
          </div>
        </header>

        <main>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/quiz" element={<Quiz />} />
            <Route path="/focus-stack" element={<FocusStack />} />
            <Route path="/memory-stack" element={<MemoryStack />} />
            <Route path="/energy-stack" element={<EnergyStack />} />
          </Routes>
        </main>

        <Footer />
      </div>
    </Router>
  );
}

export default App;