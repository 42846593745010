import { QuizResult } from '../types';

interface AnswerAnalysis {
  focusScore: number;
  sleepScore: number;
  energyScore: number;
  moodScore: number;
  memoryScore: number;
  creativityScore: number;
  stressScore: number;
  clarityScore: number;
  sleepLatency: number;
  sleepMaintenance: number;
  tempRegulation: number;
  context: string;
  age: string;
  gender: string;
}

export function analyzeAnswers(answers: { [key: number]: number | number[] }): AnswerAnalysis {
  const analysis: AnswerAnalysis = {
    focusScore: 0,
    sleepScore: 0,
    energyScore: 0,
    moodScore: 0,
    memoryScore: 0,
    creativityScore: 0,
    stressScore: 0,
    clarityScore: 0,
    sleepLatency: answers[5] as number || 0,
    sleepMaintenance: answers[6] as number || 0,
    tempRegulation: answers[7] as number || 0,
    context: '',
    age: '',
    gender: ''
  };

  // Store demographic info
  analysis.age = ['18-25', '26-35', '36-45', '46-55', '56+'][answers[1] as number] || '';
  analysis.gender = ['Male', 'Female', 'Prefer not to say'][answers[2] as number] || '';
  analysis.context = [
    'Student', 'Professional/Office Worker', 'Creative Professional',
    'Entrepreneur', 'Shift Worker', 'Athlete/Physical Performer', 'Other'
  ][answers[8] as number] || '';

  // Analyze goals (question 3)
  if (Array.isArray(answers[3])) {
    answers[3].forEach((choice) => {
      switch (choice) {
        case 0: // Improve focus
          analysis.focusScore += 2;
          analysis.clarityScore += 1;
          break;
        case 1: // Enhance memory
          analysis.memoryScore += 2;
          analysis.focusScore += 1;
          break;
        case 2: // Reduce stress
          analysis.stressScore += 2;
          analysis.moodScore += 1;
          break;
        case 3: // Increase mental energy
          analysis.energyScore += 2;
          analysis.clarityScore += 1;
          break;
        case 4: // Boost creativity
          analysis.creativityScore += 2;
          analysis.focusScore += 1;
          break;
        case 5: // Improve sleep quality
          analysis.sleepScore += 2;
          analysis.stressScore += 1;
          break;
        case 6: // Enhance overall cognitive performance
          analysis.clarityScore += 2;
          analysis.memoryScore += 1;
          analysis.focusScore += 1;
          break;
      }
    });
  }

  // Analyze challenges (question 4)
  if (Array.isArray(answers[4])) {
    answers[4].forEach((choice) => {
      switch (choice) {
        case 0: // Brain fog
          analysis.clarityScore += 2;
          analysis.focusScore += 1;
          analysis.energyScore += 1;
          break;
        case 1: // Difficulty concentrating
          analysis.focusScore += 2;
          analysis.clarityScore += 1;
          analysis.energyScore += 1;
          break;
        case 2: // Memory issues
          analysis.memoryScore += 2;
          analysis.clarityScore += 1;
          analysis.focusScore += 1;
          break;
        case 3: // Lack of mental clarity
          analysis.clarityScore += 2;
          analysis.energyScore += 1;
          analysis.focusScore += 1;
          break;
        case 4: // Low motivation
          analysis.energyScore += 2;
          analysis.moodScore += 1;
          analysis.focusScore += 1;
          break;
        case 5: // Mental fatigue
          analysis.energyScore += 2;
          analysis.clarityScore += 1;
          analysis.focusScore += 1;
          break;
        case 6: // Anxiety
          analysis.stressScore += 2;
          analysis.moodScore += 2;
          analysis.sleepScore += 1;
          break;
        case 7: // Mood swings
          analysis.moodScore += 2;
          analysis.stressScore += 1;
          break;
      }
    });
  }

  return analysis;
}

function getContextualRecommendations(analysis: AnswerAnalysis): string[] {
  const recommendations: string[] = [];

  // Context-specific recommendations
  switch (analysis.context) {
    case 'Student':
      recommendations.push('Acetyl-L-Carnitine (500mg) - Supports learning and memory formation');
      break;
    case 'Shift Worker':
      recommendations.push('Phosphatidylserine (100mg) - Helps regulate cortisol levels');
      recommendations.push('Melatonin (0.3mg) - Supports healthy sleep-wake cycles');
      break;
    case 'Athlete/Physical Performer':
      recommendations.push('Creatine (5g) - Supports cognitive performance and physical energy');
      break;
  }

  // Age-specific considerations
  if (analysis.age === '46-55' || analysis.age === '56+') {
    recommendations.push('Phosphatidylserine (100mg) - Supports cognitive function with aging');
    recommendations.push('Acetyl-L-Carnitine (500mg) - Maintains mental sharpness');
  }

  return recommendations;
}

function getSleepRecommendations(analysis: AnswerAnalysis): string[] {
  const recommendations: string[] = [];

  // Sleep onset issues
  if (analysis.sleepLatency >= 2) {
    recommendations.push('L-Theanine (200mg) - Promotes relaxation and sleep onset');
    recommendations.push('Glycine (3g) - Supports faster sleep onset');
  }

  // Sleep maintenance issues
  if (analysis.sleepMaintenance >= 2) {
    recommendations.push('Magnesium Glycinate (400mg) - Improves sleep quality and duration');
    recommendations.push('Ashwagandha (600mg) - Supports sustained sleep');
  }

  // Temperature regulation issues
  if (analysis.tempRegulation >= 2) {
    recommendations.push('Magnesium L-Threonate (2000mg) - Assists with temperature regulation and sleep quality');
  }

  return recommendations;
}

export function determineResults(analysis: AnswerAnalysis): QuizResult[] {
  const results: QuizResult[] = [];
  const threshold = 4;
  const highThreshold = 6;

  // Primary Focus & Clarity Stack (if focus or clarity scores are high)
  if (analysis.focusScore >= highThreshold || analysis.clarityScore >= highThreshold) {
    const focusStack: QuizResult = {
      category: "Focus & Clarity Enhancement",
      description: "A precisely formulated blend to optimize your concentration and mental clarity.",
      recommendations: [
        "Lion's Mane Mushroom (500mg) - Supports neural growth and focus",
        "Alpha GPC (300mg) - Enhances attention and mental clarity",
        "N-Acetyl L-Tyrosine (350mg) - Improves focus under stress"
      ]
    };

    // Add context-specific focus enhancers
    if (analysis.context === 'Student' || analysis.context === 'Professional/Office Worker') {
      focusStack.recommendations.push("Rhodiola Rosea (300mg) - Supports mental stamina");
    }

    results.push(focusStack);
  }

  // Memory & Cognitive Support (if memory score is significant)
  if (analysis.memoryScore >= threshold) {
    results.push({
      category: "Memory Optimization",
      description: "Supporting both short-term recall and long-term memory formation.",
      recommendations: [
        "Bacopa Monnieri (300mg) - Enhances memory formation",
        "Citicoline (250mg) - Supports memory and cognitive function",
        "Ginkgo Biloba (120mg) - Improves blood flow to the brain"
      ]
    });
  }

  // Mood & Stress Support (if mood or stress scores are elevated)
  if (analysis.moodScore >= threshold || analysis.stressScore >= threshold) {
    const moodStack: QuizResult = {
      category: "Mood & Stress Balance",
      description: "A calming blend to support emotional well-being and stress resilience.",
      recommendations: [
        "L-Theanine (200mg) - Promotes calm focus",
        "Ashwagandha (600mg) - Reduces stress and anxiety",
        "5-HTP (50mg) - Supports mood regulation"
      ]
    };

    // Add specific recommendations for anxiety if indicated
    if (analysis.stressScore >= highThreshold) {
      moodStack.recommendations.push("GABA (500mg) - Promotes relaxation");
      moodStack.recommendations.push("Lemon Balm (500mg) - Reduces anxiety");
    }

    results.push(moodStack);
  }

  // Energy & Vitality (if energy score is significant)
  if (analysis.energyScore >= threshold) {
    results.push({
      category: "Energy & Vitality",
      description: "Natural energy enhancement without crashes.",
      recommendations: [
        "Cordyceps Mushroom (500mg) - Supports natural energy",
        "PQQ (20mg) - Enhances mitochondrial function",
        "CoQ10 (100mg) - Supports cellular energy production",
        "B-Complex - Optimizes energy metabolism"
      ]
    });
  }

  // Sleep Optimization (if sleep score is high or sleep-related issues are present)
  if (analysis.sleepScore >= threshold || 
      analysis.sleepLatency >= 2 || 
      analysis.sleepMaintenance >= 2 || 
      analysis.tempRegulation >= 2) {
    results.push({
      category: "Sleep & Recovery",
      description: "Supporting quality sleep and overnight recovery.",
      recommendations: getSleepRecommendations(analysis)
    });
  }

  // Add contextual recommendations
  const contextRecommendations = getContextualRecommendations(analysis);
  if (contextRecommendations.length > 0) {
    results.push({
      category: "Personalized Additions",
      description: "Additional recommendations based on your specific profile.",
      recommendations: contextRecommendations
    });
  }

  return results;
}